import config from '@/utility/config';
import adminProtectedAxiosRequest from '../helpers/adminProtectedAxiosRequest';
import { getAxiosErrorMsg } from '../helpers';

export const fetchUserMemberships = async () => {
  const apiPath = `${config.communityApiBasePathCdn}/api/v1/subscriptions`;
  const { data, error } = await adminProtectedAxiosRequest.get(apiPath);

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data: data, error: null };
};

export const fetchSpecificUserMembership = async ({ subscriptionId }) => {
  const apiPath = `${config.communityApiBasePathCdn}/api/v1/subscriptions?subscriptionId=${subscriptionId}`;
  const { data, error } = await adminProtectedAxiosRequest.get(apiPath);

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data: data, error: null };
};

export const updateMembershipPlan = async ({
  subscriptionId,
  payload
}) => {
  const apiPath = `${config.communityApiBasePathCdn}/api/v1/subscriptions/${subscriptionId}/change-plan`;
  const { data, error } = await adminProtectedAxiosRequest.post(
    apiPath,
    payload
  );

  if (error) {
    return { data: null, error: getAxiosErrorMsg(error) };
  }

  return { data: data, error: null };
};
