import { useEffect, useState } from 'react';
import { MembershipObject } from './types';
import {
  CommunitySubscriptionType,
  DiscountDetails
} from '../../layouts/MembershipPageContent/components/ActiveMembershipCard/type';

type Props = {
  membershipData: MembershipObject | CommunitySubscriptionType;
};

export type PlanDetails = {
  nextBillingDate?: string;
  amount?: number;
  interval?: string;
  intervalCount?: number;
  discountDetails?: DiscountDetails;
  currency?: string;
};

const useMembershipCurrentPlan = ({ membershipData }: Props) => {
  const [currentPlan, setCurrentPlan] = useState<PlanDetails>({});
  const [nextPlan, setNextPlan] = useState<PlanDetails>({});

  const getCurrentPlan = async () => {
    const previousPlan = membershipData?.planHistory?.find(
      (plan) => plan?.nextBillingDate === membershipData?.nextBillingDate
    );

    const isPreviousPlanStillActive =
      new Date(previousPlan?.nextBillingDate) > new Date();

    if (previousPlan && isPreviousPlanStillActive) {
      setCurrentPlan({
        nextBillingDate: membershipData?.nextBillingDate,
        amount: previousPlan?.planAmount,
        interval: previousPlan?.interval,
        intervalCount: previousPlan?.intervalCount,
        discountDetails: previousPlan?.discountDetails,
        currency: previousPlan?.planCurrency
      });

      const nextPlanAmount =
        membershipData?.discountDetails?.discountedAmount ||
        membershipData?.amount;

      setNextPlan({
        nextBillingDate: membershipData?.nextBillingDate,
        amount: nextPlanAmount,
        interval: membershipData?.interval,
        intervalCount: membershipData?.intervalCount,
        currency: membershipData?.currency
      });
    } else {
      setCurrentPlan({
        nextBillingDate: membershipData?.nextBillingDate,
        amount: membershipData?.amount,
        interval: membershipData?.interval,
        intervalCount: membershipData?.intervalCount,
        discountDetails: membershipData?.discountDetails,
        currency: membershipData?.currency
      });
    }
  };

  useEffect(() => {
    if (membershipData) {
      getCurrentPlan();
    }
  }, [membershipData]);

  return { currentPlan, nextPlan };
};

export default useMembershipCurrentPlan;
